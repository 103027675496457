"use client";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import styles from './AdBanner.module.css'

import { Autoplay, Pagination, Navigation } from "swiper/modules";
import Image from "next/image";
import { homeBanner1, homeBanner2 } from "../../../public/images";

const AdBanners = ({ }) => {
  return (
    <>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        // pagination={{
        //   clickable: true,
        // }}
        // navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className={`${styles.mySwiper} w-100 h-100 px-1 mb-1`}
      >
        {/* skeleton image place holder with full witdth */}
        {[1, 2, 3].map((map, index) => (
          <>
            <SwiperSlide key={index}>
              <Image
                key={index}
                src={
                  homeBanner1}
                width={1500}
                height={200}
                className="w-100"
                alt="ad banner"
              />
            </SwiperSlide>
            <SwiperSlide key={index}>
              <Image
                key={index}
                src={
                  homeBanner2}
                width={1500}
                height={200}
                className="w-100"
                alt="ad banner"
              />
            </SwiperSlide>
          </>

        ))}

        {/* Your ad banner image here */}


      </Swiper>
    </>
  );
};

export default AdBanners;
