"use client";

import styles from "../../styles/Banner.module.css";
// import styles bundle
import 'swiper/css/bundle';
import { useEffect, useRef, useState } from "react";
import { useQueryClient, useQuery } from "@tanstack/react-query";
import { usePathname, useRouter } from "next/navigation";
import { getCity, getCondition } from "../../app/apis/getApis";
import { Searches } from "../../UserEvents/UserEvents";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';
import Image from "next/image";
import { AboutUs_Banner, exploreMore, laptopsBanner, mainBanner, mainBanner1, PhonesBanner, saleBanner } from "../../../public/images";
import { Col, Row } from "react-bootstrap";
import Link from "next/link";
const Banner = () => {
  const [selectedCondition, setSelectedCondition] = useState<any>(null);
  const [selectedLocation, setSelectedLocation] = useState<any>(null);
  const [searchInput, setSearchInput] = useState("");
  const queryClient = useQueryClient();
  const sentences = [
    "Iphone 15 pro max used",
    "samsung galaxy s20 pta-approved",
    "Oneplus 9 pro used",
  ];

  const [text, setText] = useState('');
  const [sentenceIndex, setSentenceIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [charIndex, setCharIndex] = useState(0);

  useEffect(() => {
    const typingSpeed = isDeleting ? 50 : 50;
    const sentence = sentences[sentenceIndex];

    const handleTyping = () => {
      if (!isDeleting && charIndex < sentence.length) {
        setText((prev) => prev + sentence.charAt(charIndex));
        setCharIndex((prev) => prev + 1);
      } else if (isDeleting && charIndex > 0) {
        setText((prev) => sentence.substring(0, charIndex - 1));
        setCharIndex((prev) => prev - 1);
      } else if (charIndex === sentence.length) {
        setTimeout(() => setIsDeleting(true), 2000); // Pause before deleting
      } else if (isDeleting && charIndex === 0) {
        setIsDeleting(false);
        setSentenceIndex((prevIndex) => (prevIndex + 1) % sentences.length);
      }
    };

    const timeout = setTimeout(handleTyping, typingSpeed);

    return () => clearTimeout(timeout); // Cleanup the timeout
  }, [charIndex, isDeleting, sentences, sentenceIndex]);

  const citiesData = useQuery({
    queryKey: ["nabSearchCity"],
    queryFn: getCity,
  });
  const conditionsData = useQuery({
    queryKey: ["nabSearchCondition"],
    queryFn: getCondition,
  });

  //  const citiesData=queryClient.getQueryData(["nabSearchCity"])
  //  const conditionsData=queryClient.getQueryData(["nabSearchCondition"])
  const router = useRouter();
  const pathname = usePathname();
  const searchOnChangeHandle = (e: any) => {
    const removeDisallowedAndConsecutiveChars = (input: string): string => {
      const disallowedCharsRegex = /\/{2,}|(\@|\#|\$|\%|\^|\&|\*|\,|\!|\:|\;)+|(?!\d)\/(?!\d)/g;

      let cleanedInput = input.replace(disallowedCharsRegex, '');

      cleanedInput = cleanedInput.replace(/\++/g, '+');

      cleanedInput = cleanedInput.replace(/\s+/g, ' ');

      cleanedInput = cleanedInput.replace(/\(\s*[^A-Za-z0-9]*\s*\)/g, '');

      cleanedInput = cleanedInput
        .replace(/\'{2,}/g, "''")
        .replace(/\s'+\s/g, '')
        .replace(/(?![a-z])'(?=[a-z])/g, '')
        .replace(/'+(?=\w|\d)/g, '');

      cleanedInput = cleanedInput.replace(/\s{2,}/g, ' ');
      const trailingSpace = input.endsWith(' ') ? ' ' : '';


      return cleanedInput.trim() + trailingSpace;
    };
    setSearchInput(removeDisallowedAndConsecutiveChars(e.target.value));

  };

  const searchSubmitHandle = (e: any) => {
    e.preventDefault();
    let paramss = new URLSearchParams();
    if (searchInput.trim().length > 0) {
      paramss.append("search", searchInput.trim());
    }
    if (selectedCondition) {
      paramss.append("condition_ids", `[${selectedCondition}]`);
    }
    if (selectedLocation) {
      paramss.append("city_id", `${selectedLocation}`);
    }
    if (pathname === "/products") {
      window.location.href = `/products?params=${encodeURIComponent(paramss)}`;
    } else {
      Searches(searchInput, 'Banner', `${process.env.NEXT_PUBLIC_MARKETPLACE_URL}products?params=${encodeURIComponent(paramss)}`);
      router.push(`/products?params=${encodeURIComponent(paramss)}`);
    }
  };

  return (
    <>
      <div className={`${styles.bannerWrapper}  position-relative`} id="heroBanner">

        <div className={`${styles.grid_container} m-2`}>
          {/* First Column */}
          <div className={`${styles.grid_item} bg-dark ${styles.mainBanner} `}>
            <div>
              <Swiper navigation={false} modules={[Navigation, Autoplay]} className="mySwiper"
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}

              >
                <SwiperSlide>
                  <Image className={`${styles.bannerImg} `} alt="banner" src={mainBanner} width={800} height={300} />
                </SwiperSlide>
                <SwiperSlide>
                  <Image className={`${styles.bannerImg} `} alt="banner" src={mainBanner1} width={800} height={300} />
                </SwiperSlide>

              </Swiper>
            </div>

          </div>

          {/* Second Column with two equal grid items */}
          <div className={`${styles.grid_item} ${styles.grid_subcontainer}  overflow-hidden `}>

            <div
              onClick={() => router.push(`/products?params=category_id=${2}`)}
              className="subitem overflow-hidden cursor-pointer">
              <Image className={`${styles.phonesBanner}`} alt="banner" src={PhonesBanner} width={200} height={200} />

            </div>



            <div
              onClick={() => router.push(`/products?params=category_id=${1}`)}
              className="subitem bg-danger  overflow-hidden  cursor-pointer">
              <Image className={`${styles.phonesBanner}  `} alt="banner" src={laptopsBanner} width={250} height={150} />

            </div>


          </div>
          {/* <div className={`${styles.grid_item} ${styles.grid_subcontainer_alt}  overflow-hidden `}>
            <div className={`subitem overflow-hidden ${styles.subitem} `}>
              <Image className={`${styles.phonesBanner}`} alt="banner" src={PhonesBanner} width={200} height={200} />

            </div>
            <div className="subitem bg-danger  overflow-hidden ">
              <Image className={`${styles.phonesBanner}  `} alt="banner" src={laptopsBanner} width={200} height={200} />

            </div>
          </div> */}
          {/* Third Column with varied heights */}
          <div className={`grid-item ${styles.grid_subcontainer_alt} `}>
            <div className={`${styles.subitem_tall}  overflow-hidden`}>
              <Image className={`${styles.phonesBanner} `} alt="banner" src={saleBanner} width={450} height={200} />

            </div>
            <div
              onClick={() => router.push(`/products`)}
              className={`${styles.subitem}  cursor-pointer`}>

              <Image className={`w-100`} alt="banner" src={exploreMore} width={450} height={100} />

            </div>
          </div>
        </div>


      </div>

    </>
  );
};

export default Banner;





