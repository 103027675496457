"use client";

import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import styles from "../../styles/TopSeller.module.css";
import Slider from "react-slick";
import tick from "../../../public/icons/tickk.svg";
import exclusive from "../../../public/icons/exclusive.svg";
import discountt from "../../../public/icons/discount.svg";
import popular from "../../../public/icons/popular.svg";

import { apple, samsung, oneplus, storeLogo, shopiconn } from "../../../public/images";
import Image from "next/image";
import ScrollDown from "../../components/ScrollDown";
import Link from "next/link";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchRecommendedListing, fetchTopSeller } from "../../app/apis/getApis";
import { useState } from "react";
import { marketplaceCdn } from "../../utility/env";
import { store_visit } from "../../UserEvents/UserEvents";
import { useUserStore } from "../../store/User";
import { LoadingCard } from "../../components";
import ProductCardLoading from "../SpecDetails/ProductCardLoading";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Mousewheel, Navigation, Pagination, Virtual } from 'swiper/modules';
import { useRouter } from "next/navigation";



const ImageContainer = ({ name, url }: any) => {
  const [hasimg, setHasimg] = useState(url ? true : false);



  return (
    <div className={`${styles.sellerSlider} `}>
      {hasimg ? (
        <Image
          src={`${marketplaceCdn}/${url}`}
          alt={name}
          width={100}
          height={80}
          className={`${styles.sellerImage}`}
          onError={() => {
            setHasimg(false);
          }}
        />
      ) : (
        <Image
          src={storeLogo}
          alt={name}
          width={100}
          height={80}
          className={`${styles.sellerImage}`}
          onError={() => {
            setHasimg(false);
          }}
        />
      )}
    </div>
  );
};

const TopSellers = () => {
  const [data, setData] = useState([]);
  const { user, token } = useUserStore((state: any) => state);
  const { location } = useUserStore((state: any) => state)


  const queryClient = useQueryClient()

  const items = useQuery({
    queryKey: ["best-product", location ? location : ""],
    queryFn: () => fetchRecommendedListing(10, 0, location),
    refetchOnWindowFocus: true,
    cacheTime: Infinity,
    onSuccess: (data) => {

    },
  });

  const topSellerQuery = useQuery({
    queryKey: ["topSeller"],
    queryFn: () => fetchTopSeller(),
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      setData(data.data.body.data);
    },
  });

  console.log(topSellerQuery?.data?.data?.body?.data, items?.data?.data?.body?.data)

  return (
    <div className={`${styles.topSellers} `}>
      <main className=" main  ">

        <Row className={`d-flex justify-content-center  w-100   `}>
          <Col lg="3" md="5" className={`  ${styles.top_seller_div} ${topSellerQuery?.data?.data?.body?.data.length <= 3 ? styles.top_Sellerheight : ""}  me-3`} >
            <p className="fw-500 text-center fs-18 " >
              <Image style={{ mixBlendMode: "darken" }} src={shopiconn} alt="logo" width={30} height={30} className="me-2" />
              Explore Stores

            </p>
            <Swiper
              direction={'vertical'}
              slidesPerView={topSellerQuery?.data?.data?.body?.data.length <= 3 ? 2 : 4}
              mousewheel={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              modules={[Virtual, Navigation, Pagination, Mousewheel, Autoplay]}

              navigation={false}

              className="mySwiper w-100  "
            >
              {topSellerQuery.isLoading
                ?
                <div className="d-flex flex-column flex-wrap justify-content-center w-100 mb-2 " >
                  {[1, 2, 3,].map((item) => (
                    <CardLoading />
                  ))}
                </div>

                : topSellerQuery?.data?.data?.body?.data?.map((seller, index) => (
                  <SwiperSlide className="justify-content-center " key={index}>
                    <Link href={`/store/${seller.username}`} onClick={() => store_visit(seller?.shop_id, user?.uuid, 'Top Seller', `${process.env.NEXT_PUBLIC_MARKETPLACE_URL}store/${seller.username}`, seller?.shop_name)}>
                      <div className={`${styles.top_seller_card} d-flex fit gap-2 p-2`}>
                        <div className={`${styles.seller_logo} rounded-circle border border-1`}>
                          <Image
                            src={seller.logo_path ? `${process.env.NEXT_PUBLIC_APP_URL_S3}/${seller.logo_path}` : storeLogo}
                            width={64}
                            height={64}
                            className="h-100 p-1 rounded-circle w-100"
                            alt={`${seller.shop_name} logo`}
                          />
                        </div>
                        <div className="seller-name align-content-center">
                          <p className="fw-500 fs-14 h-100 align-middle d-inline">
                            {seller.shop_name}
                          </p>
                        </div>
                      </div>
                    </Link>
                  </SwiperSlide>
                ))}



            </Swiper>

          </Col>
          <Col lg="4" md="6" className={`${styles.best_sellers}  mt-lg-0 mt-md-0 mt-3  `} >
            <p className="fw-500 fs-18 mb-2 top-p  text-center" >
              <Image style={{ mixBlendMode: "darken" }} src={discountt} alt="logo" width={40} height={40} className="me-2 " />
              Top Discounted

            </p>
            <div className=" d-lg-flex d-md-flex d-flex d-sm-flex gap-2 w-100   ">
              <div className="w-100 justify-content-center d-flex flex-column align-items-center" > {
                items?.data?.data?.body?.data.slice(0, 5).map((item, index) => (
                  <RecomendedCard
                    image={item?.item?.itemImages?.img0}
                    title={item?.listing_title}
                    price={item?.online_price}
                    salePrice={item?.online_discount}
                  />
                )
                )}</div>
              {items?.data?.data?.body?.data.length > 5 && <div className="w-100 d-block d-lg-block ">   {
                items?.data?.data?.body?.data.slice(6, 10).map((item, index) => (
                  <RecomendedCard
                    image={item?.item?.itemImages?.img0}
                    title={item?.listing_title}
                    price={item?.online_price}
                    salePrice={item?.online_discount}
                  />
                )
                )}
              </div>}
            </div>
          </Col>
          <Col className={`  mt-md-3 mt-3 mt-lg-0 d-flex  ms-lg-3 ms-md-2 overflow-hidden `} lg="4" md="12">
            <Row className={` d-lg-grid  d-md-flex w-100  gap-3  ${styles.salesDiv} `}>
              <Col lg="12" sm="5" md="5" xs="12" className={`${styles.deals} d-flex flex-column justify-content-end position-relative mb-4 h-100 `} >
                <p style={{ top: "-50px" }} className="fw-500 fs-18  mb-2 top-p position-absolute " >
                  <Image style={{ mixBlendMode: "darken" }} src={exclusive} alt="logo" width={200} height={200} className="me-2" />
                </p>
                <div className=" d-flex px-2 mt-3 ">   {
                  items?.data?.data?.body?.data.slice(0, 1).map((item, index) => (
                    <div className="d-flex align-items-center ">
                      <div className="h-100 object-fit-contain ">

                        <Image
                          height={84}
                          width={94}
                          className={`rounded-2  ${styles.recomCard} mx-2  `}
                          alt="productimg"
                          src={`${marketplaceCdn}/${item?.item?.itemImages?.img0}`}
                        />
                      </div>
                      <div className="d-flex flex-column ">

                        <p className="fs-16 fw-500 mb-2 "> {item.listing_title} </p>
                        <span
                          className={`  ${styles.condition_container}   text-white   ${item.item.condition_item === "Used" ? "used" : item.item.condition_item === "New" ? "new" : "refurbished"}   me-1 py-1 text-center fs-12`}>
                          {item.item.condition_item}
                        </span>
                        <p className={"fs-14 fw-500 mb-0  d-flex gap-2 "}> Rs  {item?.effective_price}
                          <p className={"fs-12 mb-0 fw-400 text-danger text-decoration-line-through  "}> Rs  {item.online_price}</p>
                        </p>
                      </div>
                    </div>)
                  )}

                </div>
              </Col>

              <Col lg="12" sm="6" md="6" xs="12" className={`${styles.fiveth_div} d-flex flex-column  h-100  `}>
                <p className="fw-500 fs-18 mb-2 top-p  ms-3 text-start" >
                  <Image style={{ mixBlendMode: "darken" }} src={popular} alt="logo" width={40} height={40} className="me-2 " />
                  Popular

                </p>
                <div className=" d-flex px-2 border-bottom pb-2  ">   {
                  items?.data?.data?.body?.data.slice(3, 4).map((item, index) => (
                    <div className="d-flex ">
                      <div className="h-100 object-fit-contain ">

                        <Image
                          height={84}
                          width={94}
                          className={`rounded-2  ${styles.recomCard} mx-2 `}
                          alt="productimg"
                          src={`${marketplaceCdn}/${item?.item?.itemImages?.img0}`}
                        />
                      </div>
                      <div className="d-flex flex-column ">

                        <p className="fs-16 fw-500 mb-2 "> {item.listing_title} </p>
                        <span
                          className={`  ${styles.condition_container}   text-white   ${item.item.condition_item === "Used" ? "used" : item.item.condition_item === "New" ? "new" : "refurbished"}   me-1 py-1 text-center fs-12`}

                        >
                          {item.item.condition_item}
                        </span>
                        <p className={"fs-14 fw-500 mb-0 d-flex gap-2   "}> Rs  {item?.effective_price}
                          <p className={"fs-12 mb-0 fw-400 text-danger text-decoration-line-through  "}> Rs  {item.online_price}</p>
                        </p>
                      </div>
                    </div>)
                  )}

                </div>
                <div className=" d-flex px-2 mt-2  ">   {
                  items?.data?.data?.body?.data.slice(1, 2).map((item, index) => (
                    <div className="d-flex ">
                      <div className="h-100 object-fit-contain ">

                        <Image
                          height={84}
                          width={94}
                          className={`rounded-2  ${styles.recomCard} mx-2 `}
                          alt="productimg"
                          src={`${marketplaceCdn}/${item?.item?.itemImages?.img0}`}
                        />
                      </div>
                      <div className="d-flex flex-column ">

                        <p className="fs-16 fw-500 mb-2"> {item.listing_title} </p>
                        <span
                          className={`  ${styles.condition_container}   text-white   ${item.item.condition_item === "Used" ? "used" : item.item.condition_item === "New" ? "new" : "refurbished"}   me-1 py-1 text-center fs-12`}

                        >
                          {item.item.condition_item}
                        </span>
                        <p className={"fs-14 fw-500 mb-0 d-flex gap-2  "}> Rs  {item?.effective_price}
                          <p className={"fs-12 mb-0 fw-400 text-danger text-decoration-line-through  "}> Rs  {item.online_price}</p>
                        </p>
                      </div>
                    </div>)
                  )}

                </div>
              </Col>
            </Row>
          </Col>
        </Row>

      </main>
    </div>
  );
};

export default TopSellers;



const CardLoading = () => {

  return (

    <div className={`${styles.top_seller_card} d-flex fit gap-2 p-2 ${styles.skeleton} w-100 mb-2  `}>
      <div className={`${styles.seller_logo} rounded-circle border border-1 ${styles.skeleton}`} />
      <div className="seller-name align-content-center">
        <p className={`fw-500 fs-14 h-100 align-middle d-inline ${styles.skeleton} ${styles.skeleton_text}`} />
      </div>
    </div>

  )
}

const RecomendedCard = ({ image, title, price, salePrice }: any) => {
  const router = useRouter();
  return (
    <div
      onClick={() => {
        // router.push("/specs");
      }}
      className={` ${styles.recomendedCard} d-flex gap-2 mb-2 rounded-3  px-1 bg-white w-100 border  align-items-center cursor-pointer box-shadow`}
    >

      <Image
        height={74}
        width={74}
        className={`rounded-2  ${styles.recomCard} `}
        alt="productimg"
        src={`${marketplaceCdn}/${image}`}
      />
      <p className={"fs-14 fw-500 mb-0   "}> Rs  {price}
        {salePrice > 0 && <p className={"fs-12 mb-0 fw-400 text-danger text-decoration-line-through  "}> Rs  {salePrice}</p>}
      </p>


    </div>
  );
};