// import { ProductCard } from "../../components/ProductCard";
"use client";

import Image from "next/image";
import React, { useState } from "react";
import styles from "../../styles/FeaturedCategories.module.css";

import { useRouter } from "next/navigation";
import { sendGTMEvent } from '@next/third-parties/google'
import Link from "next/link";
import {
  desktopImage,
  headPhoneImage,
  laptop,
  MobilePic,
  tabletImage,
  TV,
} from "../../../public/images";
import { motion } from "framer-motion";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useUserStore } from "../../store/User";
import { fetchRecommendedListing } from "../../app/apis/getApis";
import { ServerInsertedHtml } from "next/dist/server/future/route-modules/app-page/vendored/contexts/entrypoints";
import { marketplaceCdn } from "../../utility/env";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Virtual } from 'swiper/modules';
import { replaceSpecialCharacters } from "../../utility/Images/utils";

const FeaturedCategories = () => {
  const [sliderRef, setSliderRef] = useState<any>(null);
  const { location } = useUserStore((state: any) => state)
  const [items, setItems] = useState([])
  const router = useRouter()
  const getItems = useQuery({
    queryKey: ["recom-product", location ? location : ""],
    queryFn: () => fetchRecommendedListing(10, 0, location),
    refetchOnWindowFocus: true,
    cacheTime: Infinity,
    onSuccess: (data) => {
      setItems(
        data.data.body.data.map((item: obj) => {
          return {
            listingId: item.listing_id,
            title: item.listing_title,
            quantity: item.listed_quantity,
            sale_price: item.online_price,
            discount: item.online_discount,
            created_at: item.created_at,
            updated_at: null,
            location_id: item.location_id,
            item: item.item,
            effectivePrice: item?.effective_price,
          };
        })
      );
    },
  });

  const categories = [
    { id: "1", name: "Laptops", img: laptop },

    {
      id: "2",
      name: "Mobile",
      img: MobilePic,
    },
    { id: "3", name: "Tablets", img: tabletImage },
    { id: "4", name: "TV/Monitors", img: TV },

    { id: "5", name: "Desktop ", img: desktopImage },

    { id: "6", name: "Accessories", img: headPhoneImage },
  ];

  return (
    <div className={styles.feature_div}>
      <div className="main">
        <div className={` ${styles.container} px-sm-1`}>
          {/* <h1 className={`pb-2 ${styles.rPe_h1} `}> Categories</h1> */}
          <div className={` w-100 gap-2 ${styles.secondarydiv}`} >
            <div className={`d-flex gap-2 mx-2 mb-2 py-1 bg-white ${styles.mainDiv} `}    >
              <div
                className={`d-grid w-100 border-right  m`}>
                {categories.slice(0, 3).map((item, index) => {
                  return <span
                    as={Link}
                    onClick={() => {
                      sendGTMEvent({ event: 'Category-Clicked', value: item.name, location: 'Home', url: process.env.NEXT_PUBLIC_MARKETPLACE_URL + `products?params=category_id=${item.id}` })
                      router.push(`/products?params=category_id=${item.id}`);
                    }}
                    className={`align-items-center fw-500 d-flex gap-2  ${index === 2 ? "" : "border-bottom"}  justify-content-between p-2`} >
                    {item.name}
                    <Image
                      alt={MobilePic}
                      height={24}
                      width={24}
                      src={item.img}
                      className="me-2"
                      style={item.id === "3" ? { transform: "rotate(90deg)" } : {}}

                    />
                  </span>
                })
                }

              </div>
              <div className={`d-grid w-100  `}>
                {categories.slice(3, 6).map((item, index) => {
                  return <span
                    as={Link}
                    onClick={() => {
                      sendGTMEvent({ event: 'Category-Clicked', value: item.name, location: 'Home', url: process.env.NEXT_PUBLIC_MARKETPLACE_URL + `products?params=category_id=${item.id}` })
                      router.push(`/products?params=category_id=${item.id}`);
                    }}
                    className={`align-items-center fw-500 d-flex gap-2 ${index === 2 ? "" : "border-bottom"} justify-content-between p-2`} >
                    {item.name}
                    <Image
                      alt={MobilePic}
                      height={24}
                      width={24}
                      src={item.img}
                      className="me-2"
                    />
                  </span>
                })
                }
              </div>
            </div>
            <div className={` gap-2    overflow-hidden  px-2 ${styles.item_div}`} >

              <Swiper
                modules={[Virtual, Navigation, Pagination]}
                slidesPerView={6}
                centeredSlides={false}
                spaceBetween={30}
                navigation={true}
                virtual
                className="mySwiper w-100"
              >

                {getItems?.data?.data?.body?.data.map((item: any, index: number) => (
                  <SwiperSlide key={index} className={`  mb-2 ${styles.cardColumn}`}>
                    <div className={`${styles.cardGrid}`}>
                      <RecomendedCard
                        image={item.item.itemImages.img0}
                        title={item.listing_title}
                        price={item.effective_price}
                        salePrice={item.online_price}
                        listingId={item.listing_id}
                      />
                      {getItems?.data?.data?.body?.data[index + 1] && (
                        <RecomendedCard
                          image={getItems?.data?.data?.body?.data[index + 1].item.itemImages.img0}
                          title={getItems?.data?.data?.body?.data[index + 1].listing_title}
                          price={getItems?.data?.data?.body?.data[index + 1].effective_price}
                          salePrice={item.online_price}
                          listingId={item.listing_id}

                        />
                      )}
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>

            </div>
          </div>

        </div >


      </div >
    </div >
  );
};

export default FeaturedCategories;

const RecomendedCard = ({ image, title, price, salePrice, listingId }: any) => {
  const router = useRouter();
  return (
    <div
      onClick={() => {
        router.push(`/products/${replaceSpecialCharacters(title.replaceAll(" ", "-").replaceAll("/", "").replaceAll("--", "")).trim()}-${listingId}`);
      }}
      className={` ${styles.recomendedCard} d-flex gap-2  position-relative  bg-white w-100 border  align-items-center cursor-pointer `}
    >
      <div
        className={`text-center position-absolute top-0  d-flex justify-content-center align-items-center ${styles.featured}`}
        style={{
          // borderTopLeftRadius: "7px",
          borderBottomLeftRadius: "7px",
          // borderBottomRightRadius: "7px",
          right: "0"
        }}
      >
        <p
          className="m-0 py-0 text-white fw-bold p-2"
          style={{ fontSize: "11px", cursor: "pointer", }}
        >
          Featured
        </p>
      </div>
      <Image
        height={74}
        width={74}
        className={`rounded-2  ${styles.recomCard} `}
        alt="productimg"
        src={`${marketplaceCdn}/${image}`}
      />
      <p className={"fs-14 fw-500 mb-0   "}> Rs  {price}
        {salePrice > 0 && <p className={"fs-12 mb-0 fw-400 text-danger text-decoration-line-through  "}> Rs  {salePrice}</p>}
      </p>


    </div>
  );
};


